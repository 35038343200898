@import "../../index.scss";

.feedbackCard {
  border-radius: 12rem 12rem 12rem 2rem;
  background: rgba(16, 137, 255, 0.8);
  width: 70%;
  margin: 0 auto;
  color: white;
  padding: 2rem;
  @include shadow;
  @include Poppins;

  @include respond(phone) {
    width: 80%;
    margin: 1rem auto;
    border-radius: 9rem 9rem 9rem 2rem;
  }

  &-stars {
    font-size: 2.5rem;
  }

  &-feedback {
    font-size: 1.8rem;
    margin: 2rem;
  }

  &-name {
    font-size: 2.5rem;
    font-weight: 600;
    text-transform: uppercase;
  }
}
