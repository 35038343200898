@import "../../index.scss";

.experience {
  @include Poppins;
  color: rgb(0, 0, 0);
  text-align: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  &-title {
    font-size: 3.5rem;
    font-family: "Zilla Slab", serif;
    font-weight: 700;
    margin: 2rem;
    margin-bottom: 4rem;
    color: white;
    text-transform: uppercase;

    @include respond(phone) {
      font-size: 2.7rem;
    }
  }

  &-content {
    margin: 3rem 0;
    // display: flex;
    // justify-content: space-between;

    @include respond(phone) {
      flex-direction: column;
      margin: 1.5rem;
    }
  }
}

.experienceCard {
  margin: 1.5rem;
  color: $grey4;

  &-icon {
    & svg {
      fill: $primary;
    }
  }
  &-text {
    @include textbig;
    margin-top: 1rem;
  }
}
