@import "../../index.scss";

.hero {
  padding: 3rem 8rem;

  @include Poppins;

  @include respond(tablet) {
    padding: 3rem 1.5rem;
  }

  &-title {
    text-transform: uppercase;
    @include hero;
    color: $primary;

    @include respond(tablet) {
    }

    @include respond(phone) {
      font-size: 2.8rem;
    }
  }

  &-subtitle {
    width: 60%;
    margin: 0 auto;
    text-transform: uppercase;
    @include textbig;
    color: $primary;

    @include respond(phone) {
      width: 100%;
    }
  }
  &-contents {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5rem;

    @include respond(phone) {
      flex-direction: column;
    }
    &-left {
      width: 50%;
      @include respond(phone) {
        width: 100%;
      }
    }
    &-right {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      @include respond(phone) {
        display: none;
        width: 100%;
        margin: 5rem 0;
      }
      & img {
        width: 80%;
        height: auto;
      }
    }
  }

  &-bottom {
    padding: 2rem;
    width: 80%;
    margin: 0 auto;
    margin-top: 5rem;
    background-color: $sec10;
    border-radius: 2.5rem;

    @include respond(phone) {
      width: 100%;
      flex-direction: column;
    }

    &-contents {
      width: 100%;
      display: flex;
      @include respond(phone) {
        // width: 90%;
        flex-direction: column;
      }
      &-icon {
        width: 7%;
        display: flex;
        justify-content: center;
        align-items: center;

        @include respond(phone) {
          width: 20%;
          margin: 0 auto;
        }
        & img {
          width: 100%;
          height: auto;
        }
      }

      &-title {
        width: 93%;

        @include respond(phone) {
          width: 100%;
        }
        &-text {
          @include heading;
          color: $primary;

          @include respond(phone) {
            margin-top: 2rem;
            font-size: 2rem;
          }
        }
        &-subtext {
          @include textbig;
          color: $pri75;
        }
      }
    }
    &-form {
      margin-top: 2rem;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      @include respond(phone) {
        flex-direction: column;
      }

      & input {
        width: 35%;
        height: 4rem;
        border-radius: 1.5rem;
        border: none;
        padding: 0 1rem;
        font-size: 1.6rem;
        color: $primary;
        background-color: $white;
        margin: 1rem;
        &:focus {
          outline: none;
        }

        @include respond(phone) {
          width: 100%;
        }
      }

      & button {
        @include respond(phone) {
          margin-top: 2rem;
        }
      }
    }
  }
}
