@import "../../index.scss";

.steps {
  &-contents {
    display: flex;
    justify-content: space-between;

    @include respond(phone) {
      flex-direction: column;
    }
  }
}
