@import "../../index.scss";

.serviceCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 90%;

  @include Poppins;

  @include respond(phone) {
    width: 90%;
    margin: 1.5rem auto;
  }

  &-icon {
    width: 10rem;
    height: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    border-radius: 50%;
    background-color: $secondary;
  }

  &-name {
    font-size: 2.7rem;
    font-weight: 700;
    margin: 2rem 0;
  }

  &-info {
    font-size: 1.4rem;
    color: $grey2;
  }
}
