@import "../../index.scss";

.connect {
  text-align: center;
  padding: 2rem 10rem;

  @include respond(phone) {
    padding: 1rem 1.5rem;
  }

  &-title {
    @include heading;
    color: $primary;

    @include Monoton;
  }

  &-content {
    margin: 3rem 0;
    overflow: hidden;
    position: relative;
    border-radius: 1.5rem;

    &-letter {
      padding: 1.5rem;
      width: 90%;
      margin: 1.5rem auto;
      border-radius: 1.5rem;
      //box-shadow: 0px 0px 4px 6px rgba(216, 216, 216, 0.52);
      box-shadow: 0px 0px 4px 6px $sec25;

      @include respond(phone) {
        padding-top: 50vh;
      }
    }

    &-envelope {
      padding: 1rem;
      background-color: $tertiary;
      width: 50%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      transform: scaleY(1.02);
      clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 100%, 17% 50%);
      padding-left: 10%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;

      @include respond(phone) {
        width: 100%;
        height: 50vh;
        clip-path: polygon(0 0, 100% 0%, 100% 100%, 50% 90%, 0 100%);
        padding-bottom: 5vh;
      }

      &-title {
        padding: 2rem 5rem;
        font-size: 2rem;
        color: white;
        @include Poppins;
      }

      & svg {
        width: 50%;
        height: auto;
      }
    }
  }
}
