@import "../../index.scss";

.vc {
  margin: 1rem;
  padding: 1rem;
  padding-top: 0.5rem;
  border: 1px solid $primary;
  width: 30%;
  border-radius: 0 0 1.5rem 1.5rem;

  @include respond(phone) {
    width: 100%;
    margin: 0;
    margin-bottom: 1rem;
  }

  &-img {
    min-height: 21rem;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &-meta {
    text-align: center;

    &-name {
      @include textbig;
      font-weight: 500;
    }
    &-type {
      @include text;
    }
    &-capacity {
      @include text;
    }
  }

  &-features {
    margin: 2rem 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;

    &-feature {
      text-align: center;
      width: 40%;
      margin: 0.5rem;
      border: 2px solid $primary;
      padding: 0.5rem 1rem;
      border-radius: 1.5rem;
    }
  }

  &-footer {
    display: flex;
    justify-content: space-between;

    &-pricing {
      &-price {
        @include textbig;
        font-weight: 500;
      }
      &-info {
        @include text;
      }
      &-toPay {
        @include text;
      }
    }

    &-cta {
      display: flex;
      align-items: center;
    }
  }

  &-info {
    // margin-top: 1rem;
    background-color: $primary;
    padding: 1rem;
    color: white;
    @include text;
    font-weight: 600;
    text-align: center;
    border-radius: 0 0 1rem 1rem;
    transform: translateY(0.7rem);
  }
}
