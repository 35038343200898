@import "../../index.scss";

.vl {
  padding: 5rem 0;
  text-align: left;
  @include Poppins;

  &-hero {
    display: flex;

    &-left {
      width: 50%;

      @include respond(phone) {
        width: 100%;
      }
    }
    &-right {
      width: 50%;

      @include respond(phone) {
        display: none;
      }
    }
  }

  &-num {
    @include textbig;
    margin-top: 5rem;
  }

  &-list {
    margin: 2rem 0;
    display: flex;
    flex-wrap: wrap;

    @include respond(phone) {
      flex-direction: column;
    }
  }
}
