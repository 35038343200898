@import "../../index.scss";

.home {
  &-bottom {
    display: none;

    @include respond(phone) {
      border-radius: 1.5rem 1.5rem 0 0;
      background-color: $primary;
      padding: 1rem 0;
      display: block;
      display: flex;
      justify-content: space-evenly;
      position: fixed;
      bottom: 0;
      right: 0;
      width: 100%;
    }

    &-button {
      width: 40%;
      padding: 0.5rem 1.5rem;
      background-color: white;
      border-radius: 1.5rem;
      display: flex;
      align-items: center;
      text-decoration: none;
      color: $primary;

      & img {
        width: 3.5rem;
        height: 3.5rem;
      }

      &-text {
        margin-left: 1rem;
        text-decoration: none;
        @include text;
      }
    }
  }
}
