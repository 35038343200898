@import "../../index.scss";

.bookingbox {
  background-color: #fafafa;
  border-radius: 25px;
  width: 100%;
  box-shadow: 0px 4px 4px 2px rgba(16, 137, 255, 0.25);

  &-nav {
    display: flex;
    justify-content: space-between;
    &-item {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 33.33%;
      padding: 1rem;
      font-size: 1.6rem;
      border-radius: 25px 0 25px 0;
      text-transform: uppercase;

      cursor: pointer;
      &:hover {
        color: $pri75;
        font-weight: 700;
      }
    }
    & .active {
      font-weight: 700;
      // color: $primary;
      color: white;
      background-color: $primary;
      @include shadow;
    }
  }
  &-form {
    // height: 30rem;
    padding: 3rem;
    // display: flex;
    // justify-content: center;
    // align-items: center;

    &-selector {
      width: 100%;

      & label {
        font-size: 1.2rem;
        color: $primary;
        text-align: left;
      }

      & .formfield {
        text-align: left;
      }
      &-input {
        width: 100%;
        // margin: 1rem auto;
        margin-bottom: 2rem;
        border-radius: 1rem;
        border: none;
        @include shadowBlue;
        padding: 1.5rem;
        color: $grey2;
        position: relative;

        &-date {
          color: $grey2;
          width: 100%;
          margin-bottom: 2rem;
          border-radius: 1rem;
          border: none;
          @include shadowBlue;
          padding: 1.5rem;
        }
      }

      &-relative {
        position: relative;
      }

      &-suggestionBox {
        position: absolute;
        top: 0;
        left: 0%;
        width: 100%;
        height: 0;
        margin: 0 auto;
        background-color: red;

        z-index: 1000;

        &-suggestion {
          text-align: left;
          padding: 0.5rem;
          border-radius: 0.5rem;
          background-color: white;
          border-bottom: 1px solid black;
          @include text;
        }
        &-suggestion:hover {
          background-color: $primary;
          color: white;
        }
      }
    }
  }

  &-button {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &-enquiry {
    text-align: center;
    padding: 1rem 0;
    font-size: 1.6rem;

    &-link {
      text-decoration: none;
      color: $primary;
    }
  }
}

// input[type="datetime-local"]::-webkit-inner-spin-button,
// input[type="datetime-local"]::-webkit-calendar-picker-indicator {
//   display: none;
//   -webkit-appearance: none;
// }
