@import "../../index.scss";

.fleetCard {
  width: 90%;
  background-color: $secondary;
  border-radius: 1.5rem;
  margin: 2rem;
  @include shadow;

  @include respond(phone) {
    width: 90%;
    margin: 1rem auto;
  }

  &-image {
    width: 100%;
    height: auto;
    //

    & img {
      width: 100%;
      // height: auto;
      height: 20rem;
      border-radius: 1.5rem;
    }
  }
  &-contents {
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &-sep {
      height: 2px;
      width: 95%;
      background-color: white;
      margin: 0 auto;
      border-radius: 10rem;
    }

    &-name {
      color: white;
      font-size: 2.6rem;
      font-weight: 700;
      margin-bottom: 1rem;
    }
    &-desc {
      font-size: 1.6rem;
      margin-bottom: 1rem;
      text-align: left;
      color: $grey4;
    }

    &-details {
      color: $grey4;

      text-align: right;
      font-size: 1.6rem;
      // background-color: white;
      // color: $primary;
      text-align: left;
      padding: 1rem;
      border-top: 12px white;
      // border-radius: 1rem;
      margin-bottom: 1rem;
    }

    &-button {
      margin-top: 1rem;
    }
  }
}
