@import "../../index.scss";

.partners {
  &-contents {
    margin: 2rem 0;
  }
  @include respond(phone) {
    display: none;
  }
}
