@import "../../index.scss";

.contactCard {
  width: 50%;
  //background-color: $primary-green;
  //background-color: fade(red, 30%);
  //opacity: 0.3;
  border-radius: 1rem 4.5rem 1rem 0;

  @include respond(phone) {
    width: 100%;
  }
}
