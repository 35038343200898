@import "../../index.scss";

.about {
  @include Poppins;
  // background-color: $primary;

  &-hero {
    padding: 10rem 5rem;
    text-align: right;
    background-size: cover;
    background-repeat: no-repeat;
    // clip-path: polygon(0 10%, 100% 0, 100% 80%, 0% 100%);
    // box-shadow: 0px 0px 25px 15px rgba(0, 0, 0, 0.25);
    @include respond(phone) {
      padding: 5rem 1.5rem;
      text-align: center;
    }

    &-title {
      @include heading;
      color: white;
      margin: 2rem 0;
    }

    &-sep {
      width: 20%;
      height: 1.5rem;
      background-color: $primary;
      border-radius: 1rem;
      margin-left: auto;

      @include respond(phone) {
        width: 60%;
        margin: 0 auto;
      }
    }

    &-name {
      margin: 2rem 0;
      font-size: 12.8rem;
      font-weight: 700;
      color: white;
      line-height: 1.2;

      @include respond(phone) {
        font-size: 4.8rem;
      }
    }

    &-subtext {
      color: white;
      @include textbig;
      font-weight: 600;
      margin: 4rem 0;
    }
  }

  &-us {
    display: flex;
    flex-direction: column;
    margin: 3rem 0;

    @include respond(phone) {
      flex-direction: column;
    }

    &-sep {
      width: 80%;
      height: 1rem;
      margin: 2rem auto;
      background-color: $primary;
      border-radius: 1rem;
    }

    &-content {
      display: flex;

      @include respond(phone) {
        flex-direction: column;
      }

      &-left {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @include respond(phone) {
          width: 100%;
          padding-bottom: 5rem;
        }

        & img {
          width: 70%;
          height: auto;
          border-radius: 1rem;
          @include shadow;
          border: 1px solid $primary;

          @include respond(phone) {
            width: 90%;
          }
        }
        &-caption {
          // @include Vibes;
          @include text;
          font-weight: 400;
          color: $primary;
          margin: 2rem 0;
        }
      }

      &-right {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        @include respond(phone) {
          width: 100%;
        }

        &-content {
          width: 80%;
          padding: 2rem;

          &-heading {
            @include textbig;
            font-weight: 600;
            color: $primary;
          }

          &-info {
            @include text;
            margin-top: 2rem;
          }

          &-button {
            margin: 4rem 0;
          }
        }
      }
    }
  }

  &-philosophy {
    padding: 2rem 5rem;
    display: flex;
    background-color: $primary;

    @include respond(phone) {
      flex-direction: column;
      padding: 1.5rem;
    }

    &-heading {
      padding: 5rem;
      width: 40%;
      color: white;
      display: flex;
      align-items: center;
      @include heading;

      @include respond(phone) {
        padding: 1.5rem;
        justify-content: center;
        width: 100%;
      }
    }

    &-content {
      margin: 3rem 0;
      padding: 2rem;
      width: 60%;
      padding: 1.5rem;
      background-color: white;
      color: $grey2;
      font-size: 1.6rem;

      @include shadow;

      @include respond(phone) {
        width: 90%;
        margin: 2rem auto;
      }
    }
  }

  &-difference {
    padding: 5rem;
    margin-bottom: 5rem;
    &-heading {
      @include heading;
      color: $grey1;
    }

    &-content {
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
    }
  }

  &-faqs {
    padding-top: 1rem;
    background-color: $sec10;
    // @include shadow;
    &-heading {
      @include heading;
      margin: 3rem 0;
    }

    &-content {
      padding: 5rem;
      display: flex;

      @include respond(phone) {
        padding: 1.5rem;
      }

      @include respond(phone) {
        flex-direction: column;
      }
    }
  }
}

.differenceCard {
  margin-top: 6rem;
  position: relative;
  padding: 2rem;
  background-color: $primary;
  border-radius: 0 1.5rem 0 2rem;
  @include shadow;
  // display: flex;
  // align-items: center;

  width: 30%;

  @include respond(phone) {
    width: 100%;
  }

  &-movedown {
    margin-top: 6rem;
    position: relative;
    padding: 2rem;
    background-color: $primary;
    @include shadow;
    width: 30%;
    transform: translateY(70%);
  }

  &-id {
    // position: absolute;
    top: -70%;
    left: 5px;
    @include hero;
    color: $grey3;
  }

  &-title {
    color: white;
    @include textbig;
    font-weight: 700;
    margin: 1.5rem 0;
  }
  &-data {
    color: $grey3;
    @include text;
  }
}
