@import "../../index.scss";

.customers {
  padding: 2rem;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  &-contents {
    padding-top: 2rem;
    // display: flex;
    // justify-content: space-evenly;
    padding-bottom: 8rem;

    @include respond(phone) {
      flex-direction: column;
    }
  }
}
