@import "../../index.scss";

.stepcard {
  position: relative;
  padding-top: 3rem;
  width: 90%;
  @include Poppins;

  @include respond(phone) {
    width: 90%;
    margin: 1.5rem auto;
  }

  &-id {
    position: absolute;
    top: -2rem;
    left: 1rem;
    font-size: 7rem;
    font-weight: 700;
    color: $primary;
  }
  &-contents {
    border: 2px solid $secondary;
    border-radius: 1.5rem;
    padding: 5rem 2rem 2rem 2rem;
    display: flex;

    font-size: 1.2rem;
    &-info {
      text-align: left;
      color: $grey2;
    }
  }
}
