@import "../../index.scss";

.layout {
  position: relative;
}

////////////////////////////////
/// Styles for the Slider.

.menu {
  z-index: 200;
  @include Poppins;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: $black;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 200;
  cursor: pointer;

  &-header {
    @include hero;
    background-color: $secondary;
    height: 100vh;
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 1rem 1rem 0;

    &-content {
      height: 80vh;
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
    }
  }

  &-contents {
    width: 100%;
    // position: relative;
    margin: 3rem 8rem;

    @include respond(phone) {
      margin: 3rem;
    }
    &-cross {
      position: absolute;
      top: 2.5rem;
      right: 2.5rem;
      display: flex;
      justify-content: flex-end;
      width: 100%;

      & svg {
        width: 6rem;
        height: auto;

        @include respond(tablet) {
          width: 5rem;
        }
        @include respond(phone) {
          width: 3rem;
        }
      }
    }
    &-header {
      text-transform: uppercase;
      font-size: 5vw;
      color: $primary;
      @include Monoton;

      @include respond(phone) {
        margin-top: 3rem;
        font-size: 9vw;
      }
    }

    &-links {
      margin: 2rem;
      display: flex;
      flex-direction: column;

      &-link {
        text-decoration: none;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 5vw;
        color: white;

        @include respond(phone) {
          font-size: 9vw;
          margin: 1.5rem 0;
        }
      }

      & a {
        text-decoration: none;
      }

      &-link:hover {
        color: $primary;
      }
    }
    &-login {
      margin: 5rem auto;
      color: $primary;
      font-size: 2rem;
      background-color: white;
      width: 40%;
      padding: 1rem 2rem;
      border-radius: 1rem;
    }
  }
}

.dummyheader {
  width: 100%;
  height: 10vh;
  background-color: $primary;
}
