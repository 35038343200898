@import "../../index.scss";

.auth {
  display: flex;
  flex-direction: row-reverse;
  @include Poppins;

  @include respond(phone) {
    flex-direction: column;
  }

  &-left {
    width: 50%;
    padding: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    @include textbig;

    @include respond(phone) {
      width: 100%;
      padding: 1.5rem;
    }
  }
  &-right {
    width: 50%;
    padding: 5rem;

    &-title {
      @include heading;
      margin: 1rem 0;
    }
    &-text {
      @include textbig;
      margin: 2rem 0;
      color: $grey2;
    }

    @include respond(phone) {
      width: 100%;
      padding: 1.5rem;
    }
  }
}

.login,
.signup {
  width: 100%;
  background-color: $primary;
  padding: 2rem;
  border-radius: 2rem 0 2rem 0;
  @include shadow;
  &-header {
    text-align: center;

    &-mark {
      @include hero;
      color: white;
      margin-bottom: 2rem;
    }
    &-stat {
      @include text;
      color: $grey4;
    }
  }

  &-form {
    display: flex;
    flex-direction: column;

    padding: 3rem 5rem;
    // margin-bottom: 3rem;

    @include respond(phone) {
      padding: 1rem;
    }

    & input {
      @include text;
      min-width: 300px;
      padding: 2rem;
      margin: 1rem 0;
      border-radius: 4.5rem;
      // border: 3px solid $grey3;
      border: none;
      @include shadowBlue;

      @include respond(phone) {
        width: 100%;
        min-width: 80%;
      }
    }

    & button {
      border-radius: 4.5rem;
      margin: 1rem 0;
    }

    & .error-message {
      color: $red;
      @include text;
    }
  }

  &-toggle {
    @include text;
    // font-weight: 700;
    color: white;

    & button {
      color: white;
    }
  }
}

.suc {
  @include textbig;
  font-weight: 700;

  &-msg {
    margin-bottom: 2rem;
  }
}
