@import "../../index.scss";

.button {
  height: 4rem;
  width: auto;
  padding: 1rem 2rem;
  border: none;
  cursor: pointer;

  &-primary {
    background-color: $cta;
    color: $white;
    border-radius: 0.5rem;
    font-size: 1.6rem;
    font-weight: 700;

    &:hover {
      background-color: $sec75;
      color: black;
    }

    &-curved {
      border-radius: 2.5rem;
    }

    &-red {
      background-color: $red;
    }
  }

  &-red {
    background-color: $red;
    color: $white;
    border-radius: 0.5rem;
    font-size: 1.6rem;
    font-weight: 700;

    &:hover {
      background-color: red;
      color: $black;
    }
  }

  &-arrow {
    background-color: transparent;
    text-decoration: underline;
    text-transform: uppercase;
    border: none;
    color: white;

    font-size: 1.5rem;
  }
}
