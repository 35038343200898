*,
*::after,
*::before {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
html {
  font-size: 62.5%;
  // text-transform: uppercase;
  text-align: center;
}

body {
  font-weight: 400;
}

///////////////////////////////////
/// Variables

$white: white;
$black: #000000;
$black75: rgba(0, 0, 0, 0.75);
$black25: rgba(0, 0, 0, 0.25);
$green: rgb(62, 125, 62);
$red: rgb(226, 78, 78);

$grey1: #545454;
$grey2: #6c6c6c;
$grey3: #b8b8b8;
$grey4: #ececec;

// $primary: #5c6bc0;
// $secondary: #5c6bc0;
// $cta: #003459;
// $tertiary: #007ea7;
// $priTer: #00a8e8;
// $primary: #fdb813;
// $secondary: #fdb813;
$primary: #2b4c86;
// $primary: #1089ff;
$secondary: #1089ff;
$tertiary: #007ea7;
$priTer: #00a8e8;
$cta: #03c988;

$pri75: rgba(0, 52, 89, 0.75);

$sec75: rgba(16, 137, 255, 0.75);
$sec50: rgba(16, 137, 255, 0.5);
$sec25: rgba(16, 137, 255, 0.25);
$sec10: rgba(16, 137, 255, 0.1);

@mixin shadow {
  box-shadow: 4px 8px 13px 5px rgba(110, 110, 110, 0.25);
}
@mixin shadowBlue {
  box-shadow: 0px 4px 4px 2px rgba(16, 137, 255, 0.1);
}

//////////////////////////////////////////////
//Media Queries

// Break points -
// 400px
// 900px
// 1200px

/*
phone
tablet
tablet landscape
desktop
*/

@mixin respond($breakpoint) {
  @if ($breakpoint == tablet) {
    @media (max-width: 900px) {
      @content;
    }
  }
  @if ($breakpoint == phone) {
    @media (max-width: 430px) {
      @content;
    }
  }
}

//////////////////////////////////
/// FONT SIZES

@mixin hero {
  font-size: 6.4rem;
  font-weight: 700;
  @include respond(phone) {
    font-size: 4.2rem;
  }
}
@mixin heading {
  font-size: 4.2rem;
  font-weight: 600;
  @include respond(phone) {
    font-size: 3.6rem;
  }
}
@mixin textbig {
  font-size: 2.4rem;
  font-weight: 400;
  @include respond(phone) {
    font-size: 2rem;
  }
}
@mixin text {
  font-size: 1.6rem;
  font-weight: 300;
  @include respond(phone) {
    font-size: 1.6rem;
  }
}

@mixin Vibes {
  font-family: "Great Vibes", cursive;
}
@mixin Montserrat {
  font-family: "Montserrat", sans-serif;
}
@mixin Playfair {
  font-family: "Playfair Display", serif;
}
@mixin Poppins {
  font-family: "Poppins", sans-serif;
}
@mixin Zilla {
  font-family: "Zilla Slab", serif;
}
@mixin Vibes {
  font-family: "Great Vibes", cursive;
}
@mixin Monoton {
  font-family: "Monoton", sans-serif;
}
@mixin Poppins {
  font-family: "Poppins", sans-serif;
}

.section {
  padding: 3rem 10rem;
  position: relative;

  @include respond(phone) {
    padding: 3rem 1.5rem;
  }

  &-right {
    background-image: linear-gradient(
      270deg,
      rgba(16, 137, 255, 0.12) 0.66%,
      rgba(16, 137, 255, 0) 100.73%
    );
  }
  &-left {
    background-image: linear-gradient(
      180deg,
      rgba(16, 137, 255, 0.12) 0.66%,
      rgba(16, 137, 255, 0) 100.73%
    );
  }

  &-title {
    font-size: 3.5rem;
    font-family: "Zilla Slab", serif;
    font-weight: 700;
    margin: 2rem;
    margin-bottom: 4rem;
    color: $primary;
    text-transform: uppercase;
  }

  &-border-left {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 1.5rem;
    background-color: $secondary;
    border-radius: 0 6px 6px 0;

    @include respond(phone) {
      height: 90%;
      width: 1rem;
    }
  }

  &-border-right {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 1.5rem;
    background-color: $secondary;
    border-radius: 6px 0 0 6px;

    @include respond(phone) {
      height: 90%;
      width: 1rem;
    }
  }
}

///////////////////////////////////
/// Debugger

// * {
//   background: #000 !important;
//   color: #0f0 !important;
//   outline: solid #f00 1px !important;
// }
