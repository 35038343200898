@import "../../index.scss";

.header {
  background-color: #f8f8f8;
  border-radius: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;

  height: 10vh;
  margin: 2rem;
  z-index: -100;
  @include Poppins;
  padding: 0 2rem 0 0;

  @include respond(phone) {
    padding: 0;
    margin: 1rem;
  }

  &-logo {
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;

    // clip-path: polygon(0 0, 79% 0, 100% 100%, 0% 100%);

    // @include respond(phone) {
    //   width: 50%;
    //   clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    // }
    & img {
      margin-left: 2rem;
      width: auto;
      height: 70%;
      z-index: 125;
    }
  }

  &-links {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 60%;

    @include respond(phone) {
      display: none;
    }

    &-link {
      color: $primary;
      font-size: 1.6rem;
      font-weight: 400;
      text-decoration: none;
      transition: all 0.2s ease-in-out;
    }
  }

  &-buttons {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: end;

    &-lg {
      @include respond(phone) {
        display: none;
      }
    }
    @include respond(phone) {
      width: 80%;
    }

    & img {
      height: 80%;
      width: 5rem;
      border-radius: 50%;
      cursor: pointer;
    }

    & button {
      font-weight: 400;
      font-size: 1.6rem;
      text-decoration: none;
      background-color: $primary;
      padding: 0.5rem 2.5rem;
      color: $white;

      @include respond(phone) {
        display: none;
      }
    }
    &-burger {
      display: none;

      @include respond(phone) {
        // margin: 0 auto;
        margin-right: 1rem;
        // margin-left: auto;
        // width: 20px;
        // height: auto;
        display: block;
      }
    }
  }
}
.activeLink {
  font-weight: 700;
  border-bottom: 2px solid $primary;
}
