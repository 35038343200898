@import "../../index.scss";

.inputBox {
  padding: 0.7rem 1rem;
  color: $grey3;

  & input,
  textarea {
    width: 100%;
    padding: 1.5rem 2rem;
    color: $grey3;
    border-radius: 2.2rem;
    border: 1px solid $secondary;
    box-shadow: 4px 4px 4px $sec25;
    resize: none;

    @include Poppins;
  }
  & textarea {
    height: 10rem;
  }

  & input:focus,
  textarea:focus {
    outline: none;
  }
}

.input-box,
.text-area {
  margin-bottom: 1rem;
  width: 100%;
  padding: 1.5rem 2rem;
  color: $grey3;
  border-radius: 2.2rem;
  border: 1px solid $secondary;
  box-shadow: 4px 4px 4px $sec25;
  resize: none;
}

.input:focus,
textarea:focus {
  outline: none;
}

.text-area {
  height: 10rem;
}
