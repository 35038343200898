@import "../../index.scss";

.faq {
  width: 100%;
  border-radius: 5px;
  overflow: hidden;

  &-item {
    margin: 1rem 0;

    border-bottom: 2px solid $primary;
    // &:last-child {
    //   border-bottom: none;
    // }

    &-question {
      //   border-radius: 1.5rem;
      text-align: left;
      @include text;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 20px;
      cursor: pointer;
      background-color: $sec50;
      transition: background-color 0.2s;

      &:hover {
        background-color: $secondary;
      }

      .arrow {
        &.up::before {
          //   content: "⬆️";
          content: "⬆";
          color: white;
        }
        &.down::before {
          //   content: "⬇️";
          content: "⬇";
          color: white;
        }
      }
    }
  }
}

.faq-answer {
  text-align: left;
  @include text;
  padding: 15px 20px;
  background-color: #ffffff;
  border-top: 1px solid #e8e8e8;
}
