@import "../../index.scss";

.services {
  &-contents {
    display: flex;
    justify-content: space-evenly;
    padding-bottom: 4rem;

    @include respond(phone) {
      flex-direction: column;
    }
  }
}
