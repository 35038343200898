@import "../../index.scss";

.offerCard {
  width: 90%;
  padding: 1rem;
  margin: 2rem;
  border-radius: 1.5rem;
  background-image: linear-gradient(
    180deg,
    #8adab2 0%,
    rgba(47, 135, 198, 0.59) 100%
  );
  filter: drop-shadow(5px 5px 4px rgba(0, 0, 0, 0.25));

  // &-2 {
  //   background-image: linear-gradient(
  //     180deg,
  //     #ffc0d9 0%,
  //     rgba(47, 135, 198, 0.59) 100%
  //   );
  // }
  &-2 {
    background-image: linear-gradient(
      180deg,
      #ffc0d9 0%,
      rgba(47, 135, 198, 0.59) 100%
    );
  }
  &-3 {
    background-image: linear-gradient(
      180deg,
      #fff5c2 0%,
      rgba(47, 135, 198, 0.59) 100%
    );
  }
  @include Poppins;

  @include respond(phone) {
    width: 90%;
    margin: 1.5rem auto;
  }

  &-offer {
    font-size: 3.2rem;
    color: $white;
    font-weight: 700;
    margin: 1rem;
  }

  &-contents {
    display: flex;

    &-desc {
      padding: 1.5rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      // align-items: start;

      &-title {
        font-size: 2rem;
        color: $grey4;
        margin-bottom: 1rem;
        text-align: left;
      }

      &-cta {
        text-decoration: underline;
        color: $white;
        font-size: 1.5rem;
        padding-bottom: 0.5rem;
        margin-top: 1rem;
      }
    }
  }
}
