@import "../../index.scss";

.offers {
  &-contents {
    width: 100%;
    // display: flex;
    // justify-content: space-between;

    @include respond(phone) {
      // flex-direction: column;
    }
  }
}
