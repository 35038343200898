@import "../../index.scss";

$primary-color: #5c6bc0;
$secondary-color: #ffffff;
$accent-color: #ff7043;
$text-color: #37474f;
$icon-color: #78909c;
$box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

.conf {
  @include Poppins;
  padding: 5rem;

  color: $text-color;

  @include respond(phone) {
    padding: 1.5rem;
  }
  &-back {
    @include text;
    font-weight: 600;
    margin: 2rem 0;
    cursor: pointer;
    text-align: left;

    & span {
      font-weight: 800;
    }
  }

  &-content {
    display: flex;

    @include respond(phone) {
      flex-direction: column;
    }

    &-left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-right: 1rem;
      width: 60%;

      @include respond(phone) {
        margin-right: 0;
        width: 100%;
      }

      &-vehicle {
        // text-align: left;

        @include respond(phone) {
          margin-bottom: 1rem;
        }
        &-name {
          @include heading;
        }

        &-type {
          @include textbig;
        }
      }
    }
    &-right {
      background: linear-gradient(
        180deg,
        rgba(92, 107, 192, 0.82) 0%,
        #fff 100%
      );
      border-top: 1.5rem solid $primary-color;
      border-radius: 0 0 1.5rem 1.5rem;
      border-bottom: 2px solid $primary-color;
      width: 40%;

      @include respond(phone) {
        width: 100%;
        margin: 2rem 0;
      }
    }
  }

  &-extras {
    display: flex;
    margin: 4rem 0;

    @include respond(phone) {
      flex-direction: column;
    }

    &-ddetails {
      width: 50%;
      @include text;

      @include respond(phone) {
        width: 100%;
      }

      &-icon {
        font-size: 4rem;
        color: $primary;
      }
    }

    &-safety {
      width: 50%;
      @include text;
      background: $secondary-color;
      padding: 20px;
      border-radius: 4.5rem;
      margin-bottom: 20px;
      box-shadow: $box-shadow;

      @include respond(phone) {
        margin-top: 3rem;
        width: 100%;
      }
      p {
        .icon {
          margin-right: 5px;
          color: green; // Use a color that signifies safety
        }
      }
    }
  }
  &-limits {
    width: 100%;
    display: flex;
    margin: 2rem 0;
    margin-top: 4rem;
    @include textbig;
    @include shadowBlue;

    @include respond(phone) {
      flex-direction: column;
    }

    &-inc {
      width: 50%;
      text-align: left;
      border-left: 3px dotted $secondary-color;

      @include respond(phone) {
        width: 100%;
      }
    }
    &-exc {
      width: 50%;
      text-align: left;

      @include respond(phone) {
        width: 100%;
      }
    }
  }

  .bkDetails {
    padding: 2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-bottom: 20px;

    &-type {
      // display: flex;
      // justify-content: center;
      // align-items: center;
      width: 100%;
      margin-bottom: 1rem;
      text-align: center;
      padding: 1rem 0;
      background-color: $primary-color;
      color: white;
      @include text;
      font-weight: 600;
    }

    &-card {
      margin: 1rem;
      width: 45%;
      // max-height: 15rem;
      // overflow-y: scroll;
      background: $secondary-color;
      padding: 20px;
      border-radius: 1.5rem;
      box-shadow: $box-shadow;
      display: flex;
      align-items: center;

      @include respond(phone) {
        width: 100%;
      }

      &-details {
        &-title {
          text-align: left;
          font-size: 2rem;
          font-weight: 700;
        }

        &-location {
          @include text;
          text-align: left;
        }
      }
      &-icon {
        color: $icon-color;
        margin-right: 10px;
        font-size: 24px;
      }
    }
  }

  .addinfo {
    margin: 0 2rem;
    background: $secondary-color;
    padding: 20px;
    border-radius: 1.5rem;
    box-shadow: $box-shadow;
    margin-bottom: 20px;
    text-align: left;

    &-terms {
      @include text;
    }

    &-policy {
      @include text;
      margin-top: 1rem;
      color: rgb(62, 125, 62);
    }
  }
  .confirmation-button {
    text-align: center;
    margin-bottom: 2rem;
    button {
      background: $primary-color;
      color: $secondary-color;
      border: none;
      padding: 15px 30px;
      border-radius: 4.5rem;
      font-size: 16px;
      cursor: pointer;
      transition: background-color 0.3s;
      &:hover {
        background-color: darken($primary-color, 10%);
      }
    }
  }
}

// Responsive Design
@media (max-width: 768px) {
  .booking-confirmation .booking-details {
    flex-direction: column;
    .location-details,
    .time-details,
    .vehicle-details,
    .pricing-details {
      margin-bottom: 20px;
    }
  }
}

.carousel-container {
  position: relative;
  width: 100%;

  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  overflow: hidden;

  .carousel-slide {
    width: 100%;
    transition: all 0.5s ease;

    img {
      width: 100%;
      // max-height: 200px;
      display: block; // Remove extra space beneath the image
      height: auto; // Keep the aspect ratio of the image
      border-radius: 8px 8px 0 0;
    }
  }

  .carousel-left-arrow,
  .carousel-right-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    border: none;
    padding: 10px;
    cursor: pointer;
    z-index: 1000;

    &:hover {
      background-color: rgba(0, 0, 0, 0.7);
    }
  }

  .carousel-left-arrow {
    left: 0px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .carousel-right-arrow {
    right: 0px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
}

// Add responsive design for the carousel
@media (max-width: 768px) {
  .carousel-container {
    width: 100%;
    .carousel-left-arrow,
    .carousel-right-arrow {
      padding: 8px;
    }
  }
}

// ... previous styles

.incs,
.excs {
  background: $secondary-color;
  padding: 20px;
  // border-radius: 1.5rem 0;
  margin: 20px;
  // @include shadow;
  h3 {
    text-align: center;
    color: $primary-color;
    margin-bottom: 1rem;
  }
  ul {
    list-style: none;
    padding: 0;
    li {
      margin-bottom: 10px;
      .icon {
        margin-right: 5px;
        // color: $accent-color;
      }
    }
  }
}

.incs {
  color: $green;
  border-right: 2px dotted blue;

  @include respond(phone) {
    border-bottom: 2px dotted blue;
  }
}
.excs {
  color: $red;
}

.driver-details {
  background: $secondary-color;
  padding: 20px;
  border-radius: 4.5rem;
  margin-bottom: 20px;
  box-shadow: $box-shadow;
  display: flex;
  align-items: center;
  .icon {
    font-size: 3rem;
    margin-right: 10px;
    color: $primary-color;
  }
  p {
    margin: 0;
    strong {
      color: $text-color;
    }
  }
}

// ... responsive styles
