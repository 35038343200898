@import "../../index.scss";

.profile {
  padding: 2rem 5rem;
  min-height: 65vh;
  @include Poppins;

  @include respond(phone) {
    padding: 1.5rem;
  }

  &-user {
    display: flex;
    margin-bottom: 30px;
    // background: $primary;
    padding: 20px;
    // border-radius: 10px;
    @include shadowBlue;

    @include respond(phone) {
      flex-direction: column;
    }

    .user-profile-pic {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      margin-right: 20px;
      object-fit: cover;
      border: 2px solid #ddd;
    }

    &-details {
      @include text;
      text-align: center;
      flex: 1;

      &-name {
        @include heading;
      }
    }

    &-buttons {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      margin-left: auto;

      @include respond(phone) {
        width: 80%;
        margin: 02rem auto;
      }

      & button {
        @include respond(phone) {
          margin: 1rem 0;
        }
      }
    }
  }

  &-booking {
    &-heading {
      @include heading;
      color: $primary;
    }

    &-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
    }

    &-nobooking {
      margin: 4rem 0;
      @include text;
    }
  }

  .logout-button {
    background: #333;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
      background: darken(#333, 10%);
    }
  }
}

.bc {
  width: 30%;
  border-radius: 0 0 1.5rem 1.5rem;
  border: 1px solid $primary;
  margin: 1rem;

  @include respond(phone) {
    width: 100%;
  }

  &-type {
    background-color: $primary;
    color: white;
    @include text;
    font-weight: 600;
  }
  &-icon {
    font-size: 2.4rem;
    color: $primary;
    margin-right: 1rem;
  }

  &-img {
    width: 100%;

    & img {
      width: 100%;
      height: auto;
    }
  }

  &-details {
    padding: 0 1rem;
    &-name {
      @include textbig;
      font-weight: 700;
    }
    &-type {
      @include textbig;
      color: $grey2;
    }

    &-from,
    &-to,
    &-date {
      margin: 2rem 0;
      display: flex;
      align-items: center;
      text-align: left;
      @include text;
      color: $grey1;
    }
  }

  &-bottom {
    // border-radius: 0 0 1.5rem 1.5rem;
    // background-color: $primary;
    // color: white;
    @include textbig;
    padding: 1rem;
    display: flex;
    justify-content: space-between;

    &-price {
      display: flex;
      align-items: center;
    }

    &-canceled {
      color: $red;
    }
  }
}

.cm {
  @include text;

  &-ques {
    margin-bottom: 2rem;
  }
  &-button {
    & > * {
      margin-left: 2rem;
    }
  }
}
