@import "../../index.scss";

.fleet {
  &-contents {
    // display: flex;
    // justify-content: space-between;
    width: 100%;

    @include respond(phone) {
      flex-direction: column;
    }
  }
}
